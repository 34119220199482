///* <vue2>
import VueRouter from 'vue-router'
// </vue2> */
/* <vue3>
import { createRouter, createWebHistory } from 'vue-router'
// </vue3> */

import { guestGuard, authGuard } from '@/utils/router'
import { isRx4Route } from '@/helper.js'

const routes = [
  {
    path: '',
    component: isRx4Route
      ? () => import(/* webpackChunkName: "login" */ '@/pages/Rx4Route/Auth')
      : () => import(/* webpackChunkName: "login" */ '@/pages/Auth'),
    children: [
      ...guestGuard([
        {
          path: '/login',
          name: 'auth-login',
          component: () => import(/* webpackChunkName: "login" */ '@/pages/Auth/Login'),
        },
        {
          path: '/register',
          name: 'auth-register',
          component: () => import(/* webpackChunkName: "login" */ '@/pages/Auth/Register'),
        },
        {
          path: '/forgot',
          alias: '/forgot/:hash([0-9a-fA-F]{32})',
          name: 'auth-forgot',
          component: () => import(/* webpackChunkName: "login" */ '@/pages/Auth/Forgot'),
        },
      ], () => !isRx4Route),

      // Rx4Route routes
      ...guestGuard([
        {
          path: '/login',
          name: 'auth-login',
          component: () => import(/* webpackChunkName: "login" */ '@/pages/Rx4Route/Auth/Login'),
        },
        {
          path: '/register/:hash?',
          name: 'auth-register',
          component: () => import(/* webpackChunkName: "login" */ '@/pages/Rx4Route/Auth/Register'),
        },
        {
          path: '/forgot-pass/:hash?',
          name: 'auth-forgot',
          component: () => import(/* webpackChunkName: "login" */ '@/pages/Rx4Route/Auth/Forgot'),
        },
      ], () => isRx4Route),
    ],
  },

  ...authGuard([
    {
      path: '/vr',
      name: 'vr',
      component: () => import(/* webpackChunkName "bind-card" */ '@/pages/Vr'),
    },
    {
      path: '/equipment',
      name: 'equipment',
      component: () => import(/* webpackChunkName: "system" */ '@/pages/EquipmentNew'),
    },
    {
      path: '/to-hub',
      name: 'to-hub',
      component: () => import(/* webpackChunkName: "system" */ '@/pages/ToHub'),
    },
    {
      path: '/routes',
      component: () => import(/* webpackChunkName: "routes" */ '@/pages/Routes'),
      children: [
        {
          path: '',
          name: 'route-view',
          component: () => import(/* webpackChunkName: "routes" */ '@/pages/Routes/Item'),
        },
        {
          path: ':uid',
          name: 'route-view-item',
          component: () => import(/* webpackChunkName: "routes" */ '@/pages/Routes/Item'),
          children: [
            {
              path: ':order',
              name: 'route-view-item-order',
            },
          ],
        },
      ],
    },
    {
      path: '/chat',
      name: 'chat',
      component: () => import(/* webpackChunkName: "system" */ '@/pages/Chat'),
    },

    {
      path: '/hubs',
      component: () => import(/* webpackChunkName: "system" */ '@/pages/Hubs'),
      children: [
        {
          path: '',
          name: 'manage-hubs',
          component: () => import(/* webpackChunkName: "system" */ '@/pages/Hubs/Manage'),
        },
      ],
    },
    {
      path: '/modal-notifications',
      name: 'modal-notifications',
      component: () => import(/* webpackChunkName: "system" */ '@/pages/ModalNotifications'),
    },
    {
      path: '/complains-vendor',
      name: 'complains-vendor',
      component: () => import(/* webpackChunkName: "system" */ '@/pages/ComplainsVendor'),
    },
    {
      path: '/presentation',
      name: 'presentation',
      component: () => import(/* webpackChunkName: "system" */ '@/pages/Presentation'),
    },
    {
      path: '/facilities',
      name: 'facilities',
      component: () => import(/* webpackChunkName: "system" */ '@/pages/Facilities'),
    },
    {
      path: '/departments',
      name: 'departments',
      component: () => import(/* webpackChunkName: "system" */ '@/pages/Departments'),
    },
    {
      path: '/map',
      name: 'map',
      component: () => import(/* webpackChunkName: "system" */ '@/pages/Map'),
    },
    {
      path: '/clients',
      component: () => import(/* webpackChunkName: "clients" */ '@/pages/Clients/'),
      children: [
        {
          path: '',
          name: 'clients-list',
          component: () => import(/* webpackChunkName: "clients" */ '@/pages/Clients/List'),
        },
        {
          path: 'region-prices',
          name: 'clients-region-prices',
          component: () => import(/* webpackChunkName: "clients" */ '@/pages/Clients/RegionPrices'),
        },
        {
          path: 'region-manage',
          name: 'clients-region-manage',
          component: () => import(/* webpackChunkName: "clients" */ '@/pages/Clients/RegionManage'),
        },
        {
          path: 'schedule',
          name: 'clients-schedule',
          component: () => import(/* webpackChunkName: "clients" */ '@/pages/Clients/PickupSchedule'),
        },
        {
          path: 'pickups',
          name: 'clients-pickups',
          component: () => import(/* webpackChunkName: "clients" */ '@/pages/Clients/Pickups'),
        },
        {
          path: 'pickups-map',
          name: 'clients-pickups-map',
          component: () => import(/* webpackChunkName: "clients" */ '@/pages/Clients/PickupsMap'),
        },
      ],
    },
    {
      path: '/fb',
      component: () => import(/* webpackChunkName: "fb" */ '@/pages/Feedbacks/'),
      children: [
        {
          path: '',
          name: 'fb-overall',
        },
        {
          path: 'driver',
          name: 'fb-driver',
        },
        {
          path: 'dispatcher',
          name: 'fb-dispatcher',
        },
        {
          path: 'pharmacy',
          name: 'fb-pharmacy',
        },
        {
          path: 'call',
          name: 'fb-call',
        },
        {
          path: 'driver-dispatcher',
          name: 'fb-driver-dispatcher',
        },
        {
          path: 'dispatcher-driver',
          name: 'fb-dispatcher-driver',
        },
        {
          path: 'driver-route',
          name: 'fb-driver-route',
        },
      ],
    },
    {
      path: '/my',
      component: () => import(/* webpackChunkName: "my" */ '@/pages/My/'),
      children: [
        {
          path: 'invoices',
          name: 'my-invoices',
          component: () => import(/* webpackChunkName: "my" */ '@/pages/My/Invoices'),
        },
        {
          path: 'reports',
          name: 'my-reports',
          component: () => import(/* webpackChunkName: "my" */ '@/pages/My/Reports'),
        },
      ],
    },
    {
      path: '/account/:uid([0-9]+|my)',
      // name: 'account-my',
      component: () => import(/* webpackChunkName: "my" */ '@/pages/Account'),
      children: [
        {
          path: '',
          name: 'account-my-basic',
          component: () => import(/* webpackChunkName: "my" */ '@/pages/Account/SubPages/Basic'),
        },
        {
          path: 'subscriptions',
          name: 'account-my-subscriptions',
          component: () => import(/* webpackChunkName: "my" */ '@/pages/Account/SubPages/Rx4RouteSubscriptions'),
        },
        {
          path: 'hours',
          name: 'account-my-hours',
          component: () => import(/* webpackChunkName: "my" */ '@/pages/Account/SubPages/Hours'),
        },
        {
          path: 'security',
          name: 'account-my-security',
          component: () => import(/* webpackChunkName: "my" */ '@/pages/Account/SubPages/Security'),
        },
        {
          path: 'sessions',
          name: 'account-my-sessions',
          component: () => import(/* webpackChunkName: "my" */ '@/pages/Account/SubPages/Sessions'),
        },
        {
          path: 'notifications',
          name: 'account-my-notifications',
          component: () => import(/* webpackChunkName: "my" */ '@/pages/Account/SubPages/Notifications'),
        },
        {
          path: 'tokens',
          name: 'account-my-tokens',
          component: () => import(/* webpackChunkName: "my" */ '@/pages/Account/SubPages/Tokens'),
        },
        {
          path: 'activity',
          name: 'account-my-activity',
          component: () => import(/* webpackChunkName: "my" */ '@/pages/Account/SubPages/Activity'),
        },
        {
          path: 'stripe',
          name: 'account-my-stripe',
          component: () => import(/* webpackChunkName: "my" */ '@/pages/Account/SubPages/Stripe'),
        },
        {
          path: 'bank',
          name: 'account-my-bank',
          component: () => import(/* webpackChunkName: "my" */ '@/pages/Account/SubPages/Bank'),
        },
        {
          path: 'communications',
          name: 'account-my-communications',
          component: () => import(/* webpackChunkName: "my" */ '@/pages/Account/SubPages/Communications'),
        },
        {
          path: 'documents',
          name: 'account-my-documents',
          component: () => import(/* webpackChunkName: "my" */ '@/pages/Account/SubPages/Documents'),
        },
        {
          path: 'complainvendor',
          name: 'account-my-complainvendor',
          component: () => import(/* webpackChunkName: "my" */ '@/pages/Account/SubPages/ComplainVendor'),
        },
        {
          path: 'departments',
          name: 'account-my-departments',
          component: () => import(/* webpackChunkName: "my" */ '@/pages/Account/SubPages/Departments'),
        },
        {
          path: 'business-documents',
          name: 'account-my-business-documents',
          component: () => import(/* webpackChunkName: "my" */ '@/pages/Account/SubPages/BusinessDocuments'),
        },
        {
          path: 'not-present-call-numbers',
          name: 'account-my-not-present-call-numbers',
          component: () => import(/* webpackChunkName: "my" */ '@/pages/Account/SubPages/NotPresentCallNumbers'),
        },
        {
          path: 'order-type-settings',
          name: 'account-my-order-type-settings',
          component: () => import(/* webpackChunkName: "my" */ '@/pages/Account/SubPages/OrderTypeSettings'),
        },
        {
          path: 'balance',
          name: 'account-my-balance',
          component: () => import(/* webpackChunkName: "my" */ '@/pages/Account/SubPages/Balance'),
        },

        // by this rule includes dynamic components from pullAccount getData
        {
          path: ':group',
          name: 'account-my-settings',
          component: () => import(/* webpackChunkName: "my" */ '@/pages/Account/SubPages/Settings'),
        },
      ],
    },
    {
      path: '/system',
      component: () => import(/* webpackChunkName: "system" */ '@/pages/System/'),
      children: [
        {
          path: 'permissions',
          name: 'system-permissions',
          component: () => import(/* webpackChunkName: "system-permission" */ '@/pages/System/Permissions'),
        },
        {
          path: 'security',
          name: 'security',
          component: () => import(/* webpackChunkName: "security" */ '@/pages/System/Security'),
        },
        {
          path: 'blacklist',
          name: 'system-blacklist',
          component: () => import(/* webpackChunkName: "system" */ '@/pages/System/BlackList'),
        },
        {
          path: 'landlines',
          name: 'system-landlines',
          component: () => import(/* webpackChunkName: "system" */ '@/pages/System/LandLine'),
        },
        {
          path: 'sensitive-address',
          name: 'system-sensitive-address',
          component: () => import(/* webpackChunkName: "system" */ '@/pages/System/AddressSensitive'),
        },
        {
          path: 'user-statuses',
          name: 'system-user-statuses',
          component: () => import(/* webpackChunkName: "system" */ '@/pages/System/UserStatuses'),
        },
        {
          path: 'order-types',
          name: 'system-order-types',
          component: () => import(/* webpackChunkName: "system" */ '@/pages/System/OrderTypes'),
        },
      ],
    },
    {
      path: '/assign-routes',
      component: () => import(/* webpackChunkName: "routes" */ '@/pages/Drivers/'),
      children: [
        {
          path: '',
          name: 'assign-routes',
          component: () => import(/* webpackChunkName: "routes" */ '@/pages/Drivers/Assign'),
        },
      ],
    },
    {
      path: '/route-names',
      component: () => import(/* webpackChunkName: "routes" */ '@/pages/Routes/'),
      children: [
        {
          path: '',
          name: 'route-names',
          component: () => import(/* webpackChunkName: "routes" */ '@/pages/Routes/Names'),
        },
      ],
    },
    {
      path: '/drivers',
      name: 'staff-drivers',
      component: () => import(/* webpackChunkName: "staff" */ '@/pages/Staff/List'),
    },
    {
      path: '/staff',
      component: () => import(/* webpackChunkName: "staff" */ '@/pages/Staff/'),
      children: [
        {
          path: '',
          name: 'staff-list',
          component: () => import(/* webpackChunkName: "staff" */ '@/pages/Staff/List'),
        },
        {
          path: 'availability',
          name: 'staff-availability',
          component: () => import(/* webpackChunkName: "staff" */ '@/pages/Staff/Availability'),
        },
        {
          path: 'dispatchers',
          component: () => import(/* webpackChunkName: "staff" */ '@/pages/Staff/Dispatchers'),
          name: 'dispatchers',
        },
      ],
    },
    {
      path: '/apply',
      component: () => import(/* webpackChunkName: "apply" */ '@/pages/Apply/'),
      children: [
        {
          path: 'drivers',
          name: 'apply-drivers',
          component: () => import(/* webpackChunkName: "apply" */ '@/pages/Apply/Drivers'),
        },
        {
          path: 'calendar',
          name: 'apply-calendar',
          component: () => import(/* webpackChunkName: "apply" */ '@/pages/Apply/Calendar'),
        },
        {
          path: 'addresses',
          name: 'apply-addresses',
          component: () => import(/* webpackChunkName: "apply" */ '@/pages/Apply/Addresses'),
        },
      ],
    },

    {
      path: '/quotes',
      name: 'quotes',
      component: () => import(/* webpackChunkName: "common" */ '@/pages/Quotes'),
    },

    {
      path: '/get-route-name',
      name: 'get-route-name',
      component: () => import(/* webpackChunkName: "common" */ '@/pages/GetRouteName'),
    },
    {
      path: '/smsnew',
      name: 'smsnew',
      component: () => import(/* webpackChunkName: "common" */ '@/pages/Smsnew'),
    },
    {
      path: '/quotes-route-rx',
      name: 'quotes-route-rx',
      component: () => import(/* webpackChunkName: "common" */ '@/pages/QuotesRouteRx'),
    },
    {
      path: '/voicemails',
      name: 'voicemails',
      component: () => import(/* webpackChunkName: "common" */ '@/pages/Voicemails'),
    },
    {
      path: '/orders',
      name: 'orders-list',
      component: () => import(/* webpackChunkName: "orders" */ '@/pages/Orders/List'),
    },
    {
      path: '/orders/stat',
      name: 'orders-stat',
      component: () => import(/* webpackChunkName: "orders" */ '@/pages/Orders/Stat'),
    },

    {
      path: '/orders/new',
      alias: ['/o/new', '/o/new/'],
      name: 'orders-new',
      component: () => import(/* webpackChunkName: "new-order" */ '@/pages/Orders/New'),
    },
    {
      path: '/orders/integrations',
      name: 'orders-integrations',
      component: () => import(/* webpackChunkName: "new-order" */ '@/pages/Orders/Integrations'),
    },
    {
      path: '/qaqc',
      component: () => import(/* webpackChunkName: "qaqc" */ '@/pages/Qaqc/'),
      children: [
        {
          path: 'dashboard',
          name: 'qaqc-dashboard',
          component: () => import(/* webpackChunkName: "qaqc" */ '@/pages/Qaqc/Dashboard'),
        },
        {
          path: 'queue',
          name: 'qaqc-queue',
          component: () => import(/* webpackChunkName: "qaqc" */ '@/pages/Qaqc/Queue'),
        },
        {
          path: 'calls',
          name: 'qaqc-calls',
          component: () => import(/* webpackChunkName: "call" */ '@/pages/Qaqc/Calls'),
        },
        {
          path: 'calls-stats',
          name: 'qaqc-calls-stats',
          component: () => import(/* webpackChunkName: "call" */ '@/pages/Qaqc/CallsStats'),
        },
        {
          path: 'rejected-dashboard',
          name: 'rejected-dashboard',
          component: () => import(/* webpackChunkName: "qaqc" */ '@/pages/Qaqc/RejectedDashboard'),
        },
        {
          path: 'rejected-queue',
          name: 'rejected-queue',
          component: () => import(/* webpackChunkName: "qaqc" */ '@/pages/Qaqc/RejectedQueue'),
        },
      ],
    },
    {
      path: '/dispatcher-queue',
      name: 'dispatcher-queue',
      component: () => import(/* webpackChunkName: "new-order" */ '@/pages/DispatcherQueue'),
    },
    {
      path: '/dispatcher-queue-analytics',
      name: 'dispatcher-queue-analytics',
      component: () => import(/* webpackChunkName: "new-order" */ '@/pages/DispatcherQueueAnalytics'),
    },
    {
      path: '/support',
      name: 'support',
      component: () => import(/* webpackChunkName: "common" */ '@/pages/Support'),
    },
    // {
    //   path: '/driver-notes-report',
    //   name: 'driver-notes-report',
    //   component: () => import(/* webpackChunkName: "common" */ '@/pages/Notes/DriverReport'),
    // },
    {
      path: '/calls',
      component: () => import(/* webpackChunkName: "call" */ '@/pages/Call'),
      children: [
        {
          path: 'analytics',
          name: 'calls-analytics',
          component: () => import(/* webpackChunkName: "call" */ '@/pages/Call/Analytics'),
        },
        {
          path: 'queue',
          name: 'calls-queue',
          component: () => import(/* webpackChunkName: "call" */ '@/pages/Call/Queue'),
        },
      ],
    },
    {
      path: '/abandoned-calls',
      name: 'abandoned-calls',
      component: () => import(/* webpackChunkName: "call" */ '@/pages/AbandonedCalls'),
    },
    {
      path: '/control',
      component: () => import(/* webpackChunkName: "control" */ '@/pages/Control/'),
      children: [
        {
          path: 'fridge',
          name: 'control-fridge',
          component: () => import(/* webpackChunkName: "control" */ '@/pages/Control/Fridge'),
        },
        {
          path: 'orders',
          name: 'control-orders',
          component: () => import(/* webpackChunkName: "control" */ '@/pages/Control/Orders'),
        },
      ],
    },
    {
      path: '/performance',
      component: () => import(/* webpackChunkName: "performance" */ '@/pages/Performance'),
      children: [
        {
          path: '',
          name: 'performance-on-time',
          component: () => import(/* webpackChunkName: "performance" */ '@/pages/Performance/views/on-time.vue'),
        },
        {
          path: 'call',
          name: 'performance-call',
          component: () => import(/* webpackChunkName: "performance" */ '@/pages/Performance/views/call.vue'),
        },
        {
          path: 'message',
          name: 'performance-message',
          component: () => import(/* webpackChunkName: "performance" */ '@/pages/Performance/views/message.vue'),
        },
        {
          path: 'tracking',
          name: 'performance-tracking',
          component: () => import(/* webpackChunkName: "performance" */ '@/pages/Performance/views/tracking.vue'),
        },
        {
          path: 'qaqc',
          name: 'performance-qaqc',
          component: () => import(/* webpackChunkName: "performance" */ '@/pages/Performance/views/qaqc.vue'),
        },
        {
          path: 'tickets',
          name: 'performance-tickets',
          component: () => import(/* webpackChunkName: "performance" */ '@/pages/Performance/views/tickets.vue'),
        },
        {
          path: 'dispatcher',
          name: 'performance-dispatcher',
          component: () => import(/* webpackChunkName: "performance" */ '@/pages/Performance/views/dispatcher.vue'),
        },
      ],
    },
    {
      path: '/scan-new',
      component: () => import(/* webpackChunkName: "scan" */ '@/pages/Scan'),
      children: [
        // first row
        {
          path: '',
          name: 'scan-search-info',
          component: () => import(/* webpackChunkName: "scan" */ '@/pages/Scan/views/search-info.vue'),
        },
        {
          path: 'print-labels',
          name: 'scan-print-labels',
          component: () => import(/* webpackChunkName: "scan" */ '@/pages/Scan/views/print-labels.vue'),
        },
        {
          path: 'get-hub-name',
          name: 'scan-get-hub-name',
          component: () => import(/* webpackChunkName: "scan" */ '@/pages/Scan/views/get-hub-name.vue'),
        },
        // {
        //   path: 'get-route-name',
        //   name: 'scan-get-route-name',
        //   component: () => import(/* webpackChunkName: "scan" */ '@/pages/Scan/views/get-route-name.vue'),
        // },
        {
          path: 'see-activity',
          name: 'scan-see-activity',
          component: () => import(/* webpackChunkName: "scan" */ '@/pages/Scan/views/see-activity.vue'),
        },
        {
          path: 'validate-queue',
          name: 'scan-validate-queue',
          component: () => import(/* webpackChunkName: "scan" */ '@/pages/Scan/views/validate-queue.vue'),
        },
        {
          path: 'scan-by-filter',
          name: 'scan-by-filter',
          component: () => import(/* webpackChunkName: "scan" */ '@/pages/Scan/views/scan-by-filter.vue'),
        },
        // second row
        {
          path: 'ready-for-delivery',
          name: 'scan-ready-for-delivery',
          component: () => import(/* webpackChunkName: "scan" */ '@/pages/Scan/views/ready-for-delivery.vue'),
        },
        {
          path: 'driver-out',
          name: 'scan-driver-out',
          component: () => import(/* webpackChunkName: "scan" */ '@/pages/Scan/views/driver-out.vue'),
        },
        {
          path: 'returned',
          name: 'scan-returned',
          component: () => import(/* webpackChunkName: "scan" */ '@/pages/Scan/views/returned.vue'),
        },
        {
          path: 'ready-to-print',
          name: 'scan-ready-to-print',
          component: () => import(/* webpackChunkName: "scan" */ '@/pages/Scan/views/ready-to-print.vue'),
        },
        {
          path: 'ready-for-pickup',
          name: 'scan-ready-for-pickup',
          component: () => import(/* webpackChunkName: "scan" */ '@/pages/Scan/views/ready-for-pickup.vue'),
        },
        {
          path: 'pickup-occurred',
          name: 'scan-pickup-occurred',
          component: () => import(/* webpackChunkName: "scan" */ '@/pages/Scan/views/pickup-occurred.vue'),
        },
        {
          path: 'route-optimized',
          name: 'scan-route-optimized',
          component: () => import(/* webpackChunkName: "scan" */ '@/pages/Scan/views/route-optimized.vue'),
        },
        {
          path: 'delivery-attempted',
          name: 'scan-delivery-attempted',
          component: () => import(/* webpackChunkName: "scan" */ '@/pages/Scan/views/delivery-attempted.vue'),
        },
        {
          path: 'rejected',
          name: 'scan-rejected',
          component: () => import(/* webpackChunkName: "scan" */ '@/pages/Scan/views/rejected.vue'),
        }, {
          path: 'investigation',
          name: 'scan-investigation',
          component: () => import(/* webpackChunkName: "scan" */ '@/pages/Scan/views/investigation.vue'),
        },
        {
          path: 'back-to-pharmacy',
          name: 'scan-back-to-pharmacy',
          component: () => import(/* webpackChunkName: "scan" */ '@/pages/Scan/views/back-to-pharmacy.vue'),
        },
        {
          path: 'other-date-delivery',
          name: 'scan-other-date-delivery',
          component: () => import(/* webpackChunkName: "scan" */ '@/pages/Scan/views/other-date-delivery.vue'),
        },
        {
          path: 'ready-to-optimize',
          name: 'scan-ready-to-optimize',
          component: () => import(/* webpackChunkName: "scan" */ '@/pages/Scan/views/ready-to-optimize.vue'),
        },
        {
          path: 'on-its-way-to-facility',
          name: 'scan-on-its-way-to-facility',
          component: () => import(/* webpackChunkName: "scan" */ '@/pages/Scan/views/on-its-way-to-facility.vue'),
        },
        {
          path: 'draft',
          name: 'scan-draft',
          component: () => import(/* webpackChunkName: "scan" */ '@/pages/Scan/views/draft.vue'),
        },
      ],
    },
    {
      path: '/scanner',
      component: () => import(/* webpackChunkName: "scanner" */ '@/pages/Scanner/'),
      children: [
        // {
        //   path: 'route-name',
        //   name: 'scanner-route-name',
        //   component: () => import(/* webpackChunkName: "scanner" */ '@/pages/Scanner/RouteName'),
        // },
        {
          path: 'unlock-for-driver',
          name: 'scanner-unlock-for-driver',
          component: () => import(/* webpackChunkName: "scanner" */ '@/pages/Scanner/UnlockForDriver'),
        },
        {
          path: 'as-driver',
          name: 'scanner-as-driver',
          component: () => import(/* webpackChunkName: "scanner" */ '@/pages/Scanner/AsDriver'),
        },
        {
          path: 'order-editor',
          name: 'scanner-order-editor',
          component: () => import(/* webpackChunkName: "scanner" */ '@/pages/Scanner/OrderEditor'),
        },
      ],
    },
    {
      path: '/address-book',
      name: 'address-book',
      component: () => import(/* webpackChunkName: "address-book" */ '@/pages/AddressBook'),
    },
    {
      path: '/prime-rx-orders',
      name: 'prime-rx-orders',
      component: () => import(/* webpackChunkName: "prime-rx" */ '@/pages/PrimeRxOrders'),
    },
    {
      path: '/integration-statistics',
      name: 'integration-statistics',
      component: () => import(/* webpackChunkName: "integration" */ '@/pages/IntegrationStatistics'),
    },
    {
      path: '/finances-rx4route',
      name: 'finances-rx4route',
      component: () => import(/* webpackChunkName: "finances-rx4route" */ '@/pages/FinancesRx4Route'),
    },
    {
      path: '/tickets',
      name: 'tickets',
      component: () => import(/* webpackChunkName: "tickets" */ '@/pages/Tickets/'),
      children: [
        {
          path: 'dashboard',
          name: 'tickets-dashboard',
          component: () => import(/* webpackChunkName: "tickets" */ '@/pages/Tickets/Dashboard'),
        },
        {
          path: 'queue',
          name: 'tickets-queue',
          component: () => import(/* webpackChunkName: "tickets" */ '@/pages/Tickets/Queue'),
        },
        {
          path: 'analytic',
          name: 'tickets-analytic',
          component: () => import(/* webpackChunkName: "tickets" */ '@/pages/Tickets/Analytic'),
        },
      ],
    },

    {
      path: '/tip',
      name: 'tip',
      component: () => import(/* webpackChunkName: "finances" */ '@/pages/Tip'),
      children: [
        {
          path: 'driver',
          name: 'tip-driver',
        },
        {
          path: 'dispatcher',
          name: 'tip-dispatcher',
        },
        {
          path: 'pharmacy',
          name: 'tip-pharmacy',
        },
      ],
    },
    {
      path: '/finances',
      name: 'finances',
      component: () => import(/* webpackChunkName: "finances" */ '@/pages/Finances'),
      children: [
        {
          path: 'summary',
          name: 'finances-summary',
          component: () => import(/* webpackChunkName: "finances" */ '@/pages/Finances/Summary'),
        },
        {
          path: 'revenue',
          name: 'finances-revenue',
          component: () => import(/* webpackChunkName: "finances" */ '@/pages/Finances/Revenue'),
        },
        {
          path: 'payouts',
          name: 'finances-payouts',
          component: () => import(/* webpackChunkName: "finances" */ '@/pages/Finances/Payouts'),
        },
        {
          path: 'transactions-report',
          name: 'finances-transactions-report',
          component: () => import(/* webpackChunkName: "finances" */ '@/pages/Finances/NachaTransactions'),
        },
        {
          path: 'report',
          name: 'finance-report',
          component: () => import(/* webpackChunkName: "finances" */ '@/pages/Finances/Report'),
          children: [
            {
              path: 'stripe-transactions',
              name: 'finances-report-stripe-transactions',
              component: () => import(/* webpackChunkName: "finances" */ '@/pages/Finances/Report/StripeTransactions'),
            },
            {
              path: 'common',
              name: 'finances-report-common',
              component: () => import(/* webpackChunkName: "finances" */ '@/pages/Finances/Report/Common'),
            },
          ],
        },
        {
          path: 'bhstry',
          name: 'finances-bhstry',
          component: () => import(/* webpackChunkName: "finances" */ '@/pages/Finances/Bhstry'),
        },
        {
          path: 'invoices',
          name: 'finances-invoices',
          component: () => import(/* webpackChunkName: "finances" */ '@/pages/Finances/Invoices'),
        },
        {
          path: 'dexps',
          name: 'finances-dexps',
          component: () => import(/* webpackChunkName: "finances" */ '@/pages/Finances/Dexps'),
        },
        {
          path: 'manual-revenue',
          name: 'finances-manual-revenue',
          component: () => import(/* webpackChunkName: "finances" */ '@/pages/Finances/ManualRevenue'),
        },
        {
          path: 'bank-expenses',
          name: 'finances-bank-expenses',
          component: () => import(/* webpackChunkName: "finances" */ '@/pages/Finances/BankExpenses'),
        },
        {
          path: 'bank-transactions',
          name: 'finances-bank-transactions',
          component: () => import(/* webpackChunkName: "finances" */ '@/pages/Finances/BankTransactions'),
        },
        {
          path: 'operation-expenses',
          name: 'finances-operation-expenses',
          component: () => import(/* webpackChunkName: "finances" */ '@/pages/Finances/OperationExpenses'),
        },
        {
          path: 'bank-accounts',
          name: 'finances-bank-accounts',
          component: () => import(/* webpackChunkName: "finances" */ '@/pages/Finances/BankAccounts'),
        },
        {
          path: 'dtips',
          name: 'finances-dtips',
          component: () => import(/* webpackChunkName: "finances" */ '@/pages/Finances/Dtips'),
        },
        {
          path: 'rx4-revenue',
          name: 'finances-rx4-revenue',
          component: () => import(/* webpackChunkName: "finances" */ '@/pages/Finances/Rx4Revenue'),
        },
        {
          path: 'pade-statistics',
          name: 'pade-statistics',
          component: () => import(/* webpackChunkName: "finances" */ '@/pages/Finances/PadeStatistics'),
        },
        {
          path: 'carrier-revenue',
          name: 'finances-carrier-revenue',
          component: () => import(/* webpackChunkName: "finances" */ '@/pages/Finances/CarrierRevenue'),
        },
      ],
    },
    {
      path: '/bind-card',
      name: 'bind-card',
      component: () => import(/* webpackChunkName "bind-card" */ '@/pages/Rx4Route/BindCard'),
    },
    {
      path: '/lead-status-labels',
      name: 'lead-status-labels',
      component: () => import(/* webpackChunkName: "lead" */ '@/pages/Lead/LeadStatusLabel'),
    },
  ]),
]

///* <vue2>
const router = new VueRouter({
  routes,
  mode: 'history',
})
router.usable = VueRouter
router.module = 'router'
// </vue2> */
/* <vue3>
const router = createRouter({
  routes,
  history: createWebHistory(),
})
// </vue3> */

export default router
